<!--/**-->
<!--* 自动生成 vue列表代码【模块名：物流信息订阅记录】-->
<!--* 生成日期：2024/03/04 13:55:21-->
<!--* 生成路径: src/pages/logistic_subscribe/LogisticSubscribeList.vue-->
<!--* 生成人：admin-->
<!--*/-->
<template>
  <div>
    <PageHeaderLayout>
      <div class='main-page-content'>
        <div class='table-search'>
          <el-form :model='searchCondition' inline size='mini'>
            <el-form-item label='物流单号'>
              <el-input v-model='searchCondition.nu' placeholder='物流单号' clearable></el-input>
            </el-form-item>
            <el-form-item label='快递编码'>
              <el-input v-model='searchCondition.com' placeholder='快递编码' clearable></el-input>
            </el-form-item>
            <el-form-item label='电话号码'>
              <el-input v-model='searchCondition.phone' placeholder='电话号码' clearable></el-input>
            </el-form-item>
            <el-form-item label='回调ID'>
              <el-input v-model='searchCondition.callback_id' placeholder='回调ID' clearable></el-input>
            </el-form-item>
            <el-form-item label='回调地址'>
              <el-input v-model='searchCondition.callback_url' placeholder='回调地址' clearable></el-input>
            </el-form-item>
            <el-form-item label='返回代码'>
              <el-input v-model='searchCondition.return_code' placeholder='订阅返回信息代码' clearable></el-input>
            </el-form-item>

            <el-form-item label='创建时间'>
              <el-date-picker
                v-model='searchCondition.date_range'
                type='daterange'
                align='right'
                unlink-panels
                range-separator='至'
                start-placeholder='开始日期'
                end-placeholder='结束日期'
                value-format='yyyy-MM-dd'
                :picker-options='pickerOptions'>
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type='primary' icon='el-icon-search' :disabled='loading' :loading='loading'
                         @click='handleQuery'>搜索
              </el-button>
            </el-form-item>
          </el-form>
        </div>
        <!--        操作栏-->
        <el-row class='mb8' :gutter='10'>
          <el-col :span='1.5'>
            <el-button type='primary' icon='el-icon-plus' @click='handleAdd'>新增</el-button>
          </el-col>
          <el-col :span='1.5'>
            <el-button icon='el-icon-upload' @click='openImport=true' v-if='hasAuthority("logistic_subscribe_import")'>导入</el-button>
          </el-col>
          <el-col :span='1.5'>
            <export-btn ref='refLogisticSubscribeExport' :can-export='hasAuthority("logistic_subscribe_export")' @export='handleExport'></export-btn>
            <!--            <el-button icon='el-icon-download' @click='handleExport'>导出</el-button>-->
          </el-col>
        </el-row>
        <!--    渲染表格-->
        <div class='default-table'>
          <MlPageTable :all-columns='defaultColumns'
                       :table-name='tableName'
                       :data-list='dataList'
                       :limit.sync='pageData.page_size'
                       :page.sync='pageData.current_page'
                       :total.sync='total'
                       :height='height'
                       highlight-current-row
                       :row-class-name='tableRowClassName'
                       @row-click='rowClickEvent'
                       @getList='getList'>
            <el-table-column align='center' width='120' label='操作'>
              <template slot-scope='{row,$index}'>
                <el-button type='text' icon='el-icon-edit' @click='handleEdit(row)'>修改</el-button>
                <el-button type='text' icon='el-icon-delete' @click='handleDelete(row,$index)'>删除</el-button>
              </template>
            </el-table-column>
          </MlPageTable>
        </div>
      </div>

      <!-- 添加或修改对话框 -->
      <el-dialog :title='title' :visible.sync='open' width='800px' append-to-body :close-on-click-modal='false'>
        <el-form ref='form' :model='form' :rules='rules' label-width='100px'>
          <el-form-item label='物流单号' prop='nu'>
            <el-input v-model='form.nu' placeholder='请输入物流单号' clearable />
          </el-form-item>
          <el-form-item label='快递编码' prop='com'>
            <el-input v-model='form.com' placeholder='请输入快递编码' clearable />
          </el-form-item>
          <el-form-item label='电话号码' prop='phone'>
            <el-input v-model='form.phone' placeholder='请输入电话号码' clearable />
          </el-form-item>
          <el-form-item label='回调ID' prop='callback_id'>
            <el-input v-model='form.callback_id' placeholder='请输入回调ID' clearable />
          </el-form-item>
          <el-form-item label='回调地址' prop='callback_url'>
            <el-input v-model='form.callback_url' placeholder='请输入回调地址' clearable />
          </el-form-item>
          <el-form-item label='订阅返回信息代码' prop='return_code'>
            <el-input v-model='form.return_code' placeholder='请输入订阅返回信息代码' clearable />
          </el-form-item>

        </el-form>
        <div slot='footer' class='dialog-footer'>
          <el-button type='primary' @click='submitForm' :disabled='loadingCommit' :loading='loadingCommit'>确 定
          </el-button>
          <el-button @click='cancel'>取 消</el-button>
        </div>
      </el-dialog>

      <!--      导入-->
      <ImportLogisticSubscribe :visible.sync='openImport' v-if='hasAuthority("logistic_subscribe_import")' @imported='handleQuery' />

    </PageHeaderLayout>
  </div>
</template>

<script>
import MlPageTable from '@/components/common/MlPageTable'
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ExportBtn from '@/components/export/ExportBtn'
import ImportLogisticSubscribe from './ImportLogisticSubscribe'

export default {
  name: 'LogisticSubscribeList',
  components: { ImportLogisticSubscribe, ExportBtn, PageHeaderLayout, MlPageTable },
  data() {
    return {
      tableName: 'logistic_subscribe',
      loading: false,
      loadingCommit: false,
      dataList: [],
      total: 0,
      height: 800,
      searchCondition: {
        date_range: [],
        nu: null,
        com: null,
        phone: null,
        callback_id: null,
        callback_url: null,
        return_code: null
      },
      pageData: { current_page: 1, page_size: 100 },
      orderBy: { id: 'asc' },
      pickerOptions: {
        shortcuts: [{
          text: '本月',
          onClick(picker) {
            picker.$emit('pick', [new Date(), new Date()])
          }
        }, {
          text: '今年至今',
          onClick(picker) {
            const end = new Date()
            const start = new Date(new Date().getFullYear(), 0)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近六个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setMonth(start.getMonth() - 6)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      defaultColumns: [{ hide: false, prop: 'id', label: '序号', width: 80, sortable: false, fixed: false },
        { hide: false, prop: 'nu', label: '物流单号', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'com', label: '快递编码', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'phone', label: '电话号码', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'callback_id', label: '回调ID', width: 140, sortable: true, fixed: false },
        { hide: false, prop: 'callback_url', label: '回调地址', width: 320, sortable: true, fixed: false },
        { hide: false, prop: 'return_code', label: '订阅返回信息代码', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'created_at', label: '创建时间', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'updated_at', label: '修改时间', width: 120, sortable: false, fixed: false }
      ],
      // 弹出层标题
      title: '添加/修改-物流信息订阅记录',
      // 是否显示弹出层
      open: false,
      // 表单校验
      rules: {
        nu: [{ required: true, message: '物流单号不能为空', trigger: 'blur' }],
        com: [{ required: true, message: '快递编码不能为空', trigger: 'blur' }],
        phone: [{ required: true, message: '电话号码不能为空', trigger: 'blur' }],
        callback_id: [{ required: true, message: '回调ID不能为空', trigger: 'blur' }],
        callback_url: [{ required: true, message: '回调地址不能为空', trigger: 'blur' }],
        return_code: [{ required: true, message: '订阅返回信息代码不能为空', trigger: 'blur' }]
      },
      // 表单参数
      form: {},
      //是否显示弹出层:导入框
      openImport: false,
      //筛选条件缓存key
      pageSearchKey: 'page_search_key:logistic_subscribe'
    }
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    rowClickEvent(row, column, event) {
      // this.$notify.info(`数据ID：${row.id}`)
    },
    // eslint-disable-next-line no-unused-vars
    tableRowClassName({ row, rowIndex }) {
      return 'default-table-row'
    },
    handleSearchCondition() {
      let condition = {}
      Object.keys(this.searchCondition).forEach((field) => {
        if (field !== 'date_range' && this.searchCondition[field]) {
          // console.log(Object.prototype.toString.call(this.searchCondition[field]))
          // console.log((this.searchCondition[field]).constructor)
          // console.log(typeof (this.searchCondition[field]))

          condition[field] = this.searchCondition[field]
        }
      })

      if (this.searchCondition.date_range) {
        condition.date_range = this.searchCondition.date_range
      }

      // 分页 +排序
      Object.assign(condition, this.pageData, { orderBy: this.orderBy })

      return condition
    },
    handleQuery() {
      this.pageData.current_page = 1
      this.getList()
      //缓存筛选条件，刷新后可以继续使用
      sessionStorage.setItem(this.pageSearchKey, JSON.stringify(this.searchCondition))
    },
    async getList() {
      this.dataList = []
      this.loading = true
      let params = this.handleSearchCondition()
      let { list, pages } = await this.$api.getLogisticSubscribeList(params)
      // this.dataList = [...this.dataList, ...list]
      this.dataList = list
      this.pageData.current_page = pages.current_page || 1
      this.pageData.page_size = pages.page_size || 100
      this.total = pages.total
      this.loading = false
    },
    /** 删除按钮 */
    handleDelete(row, index) {
      if (row.id) {
        //真实删除
        this.$confirm('此操作将删除该行数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.delRow(row.id)
          this.dataList.splice(index, 1)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
      } else {
        this.tableData.splice(index, 1)
      }
    },
    delRow(id) {
      this.$api.deleteLogisticSubscribe(id)
    },
    /** 修改按钮 */
    handleAdd() {
      this.open = true
      this.title = '添加-物流信息订阅记录'
      // let { info } = await this.$api.getLogisticSubscribeInfo(row.id)
      this.form = {}
    },
    /** 修改按钮 */
    async handleEdit(row) {
      this.open = true
      this.title = '修改-物流信息订阅记录'
      // let { info } = await this.$api.getLogisticSubscribeInfo(row.id)
      this.form = { ...row }
    },

    /** 提交按钮 */
    async submitForm() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.loadingCommit = true
          this.$api.saveLogisticSubscribe(this.form).then(id => {
            if (id) {
              if (!this.form.id)
                this.msgSuccess('新增成功')
              else
                this.msgSuccess('修改成功')

              this.form.id = id
              this.open = false
              this.getList()
            } else {
              this.msgError('操作失败')
            }
            setTimeout(() => {
              this.loadingCommit = false
            }, 500)
          })

        }
      })
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.form = {}
    },
    //导出表格
    async handleExport() {
      try {
        let searchCondition = this.handleSearchCondition()
        let response = await this.$api.exportLogisticSubscribes(searchCondition)
        let name = `订阅记录`

        this.$refs.refLogisticSubscribeExport.exportSaveXlsx(response, name)
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
        // alert(JSON.stringify(e))
        this.$refs.refLogisticSubscribeExport.hideLoading()
      }
    },
    handleImport() {
      this.$api.importCreatePressSelectionInfo()
    }
  },
  created() {
    if (sessionStorage.getItem(this.pageSearchKey)) {
      this.searchCondition = JSON.parse(sessionStorage.getItem(this.pageSearchKey))
    }
    this.handleQuery()
  }
}
</script>

<style scoped>

</style>